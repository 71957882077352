import React, { lazy, useState } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
// routing
import config from './config';
import themes from 'themes';
// project imports
import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import MainLayout from 'layout/MainLayout';
import DefaultLayout from 'layout/DefaultLayout';
import { genConst } from 'store/constant';
//Firebase
import { onAuthStateChanged } from 'firebase/auth';
import { authentication } from 'config/firebase';
import { getProfileUser } from 'config/firebaseEvents';

//const NotFound = Loadable(lazy(() => import('views/pages/error/NotFound')));
const AuthSigninDefault = Loadable(lazy(() => import('views/pages/login/login/Signin')));
const AuthSignupDefault = Loadable(lazy(() => import('views/pages/login/login/Signup')));
const AuthRecoveryDefault = Loadable(lazy(() => import('views/pages/login/login/PasswordRecover')));
// ADMIN DASHBOARD
const DashboardAdmin = Loadable(lazy(() => import('views/dashboard/Admin')));
const UserProfile = Loadable(lazy(() => import('views/dashboard/Admin/Profile/UserProfile')));
const UserSecurity = Loadable(lazy(() => import('views/dashboard/Admin/Profile/UserSecurity')));
const Courses = Loadable(lazy(() => import('views/dashboard/Admin/Courses/Courses')));
const CourseComments = Loadable(lazy(() => import('views/dashboard/Admin/Courses/CourseComments')));
const CourseResponses = Loadable(lazy(() => import('views/dashboard/Admin/Courses/CourseResponses')));
const AddCourse = Loadable(lazy(() => import('views/dashboard/Admin/Courses/AddCourse')));
const EditCourse = Loadable(lazy(() => import('views/dashboard/Admin/Courses/EditCourse')));
const Course = Loadable(lazy(() => import('views/dashboard/Admin/Courses/Course')));
const AddUserCourse = Loadable(lazy(() => import('views/dashboard/Admin/Courses/AddUser')));
const AdminUsers = Loadable(lazy(() => import('views/dashboard/Admin/AdminUsers/AdminUsers')));
const Users = Loadable(lazy(() => import('views/dashboard/Admin/Users/Users')));
const Share = Loadable(lazy(() => import('views/dashboard/Admin/Share/Share')));
const Settings = Loadable(lazy(() => import('views/dashboard/Admin/Settings/Settings')));
const Banner = Loadable(lazy(() => import('views/dashboard/Admin/Banner/Banner')));
const Vouchers = Loadable(lazy(() => import('views/dashboard/Admin/Vouchers/Vouchers')));
const Payments = Loadable(lazy(() => import('views/dashboard/Admin/Payments/Payments')));
const Notifications = Loadable(lazy(() => import('views/dashboard/Admin/Notifications/Notifications')));
const NotFoundAdm = Loadable(lazy(() => import('views/dashboard/Admin/Error/NotFound')));
// HISTORIC
const Logs = Loadable(lazy(() => import('views/dashboard/Admin/Logs/Logs')));

// DEFAULT DASHBOARD
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Main')));
const CoursesDefault = Loadable(lazy(() => import('views/dashboard/Main/Courses/Courses')));
const CourseDefault = Loadable(lazy(() => import('views/dashboard/Main/Courses/Course')));
const CourseFreeDefault = Loadable(lazy(() => import('views/dashboard/Main/Courses/CoursesFree')));
const UserProfileDefault = Loadable(lazy(() => import('views/dashboard/Main/Profile/UserProfile')));
const UserSecurityDefault = Loadable(lazy(() => import('views/dashboard/Main/Profile/UserSecurity')));
const NotificationsDefault = Loadable(lazy(() => import('views/dashboard/Main/Notifications/Notifications')));
const Subscribe = Loadable(lazy(() => import('views/dashboard/Main/Subscribe')));
const Payment = Loadable(lazy(() => import('views/dashboard/Main/Subscribe/Payments')));
const Success = Loadable(lazy(() => import('views/dashboard/Main/Subscribe/Success')));
const Failure = Loadable(lazy(() => import('views/dashboard/Main/Subscribe/Failure')));
const Pending = Loadable(lazy(() => import('views/dashboard/Main/Subscribe/Pending')));
const NotificationPayment = Loadable(lazy(() => import('views/dashboard/Main/Subscribe/Notification')));
const SuccessSend = Loadable(lazy(() => import('views/dashboard/Main/Subscribe/SuccessSend')));
const NotFound = Loadable(lazy(() => import('views/dashboard/Main/Error/NotFound')));

const App = () => {
  const customization = useSelector((state) => state.customization);
  const [profile, setProfile] = useState(null);

  React.useEffect(() => {
    onAuthStateChanged(authentication, (user) => {
      if (user) {
        getProfileUser(user.uid).then((pro) => {
          setProfile(pro);
        });
      }
    });
  }, [profile]);

  return (
    <ThemeProvider theme={themes(customization)}>
      <Router basename={config.basename}>
        <Routes>
          <Route element={<MinimalLayout />} path="/" exact>
            <Route element={<AuthSigninDefault />} path="/" exact />
            <Route element={<AuthSigninDefault />} path="auth/signin" exact />
            <Route element={<AuthSignupDefault />} path="auth/signup" exact />
            <Route element={<AuthRecoveryDefault />} path="auth/password-recovery" exact />
          </Route>
          {profile == genConst.CONST_PRO_ADM ? (
            <Route element={<MainLayout />} path="/admin" exact>
              <Route element={<DashboardAdmin />} path="dashboard" exact />
              <Route element={<AdminUsers />} path="admin-users" exact />
              <Route element={<Users />} path="users" exact />
              <Route element={<Courses />} path="courses" exact />
              <Route element={<Course />} path="course" exact />
              <Route element={<CourseComments />} path="comments" exact />
              <Route element={<CourseResponses />} path="responses" exact />
              <Route element={<Banner />} path="banner" exact />
              <Route element={<Vouchers />} path="vouchers" exact />
              <Route element={<Payments />} path="payments" exact />
              <Route element={<AddCourse />} path="add-course" exact />
              <Route element={<EditCourse />} path="edit-course" exact />
              <Route element={<AddUserCourse />} path="add-user-course" exact />
              <Route element={<Share />} path="share" exact />
              <Route element={<Settings />} path="settings" exact />
              <Route element={<Notifications />} path="notifications" exact />
              <Route element={<UserProfile />} path="profile" exact />
              <Route element={<UserSecurity />} path="security" exact />
              <Route element={<Logs />} path="logs" exact />
              <Route element={<NotFoundAdm />} path="404" exact />
              <Route path="*" element={<Navigate to="404" />} />
            </Route>
          ) : (
            <Route element={<DefaultLayout />} path="/app" exact>
              <Route element={<DashboardDefault />} path="dashboard" exact />
              <Route element={<CoursesDefault />} path="courses" exact />
              <Route element={<CourseFreeDefault />} path="free-courses" exact />
              <Route element={<CourseDefault />} path="course" exact />
              <Route element={<NotificationsDefault />} path="notifications" exact />
              <Route element={<UserProfileDefault />} path="profile" exact />
              <Route element={<UserSecurityDefault />} path="security" exact />
              <Route element={<Subscribe />} path="subscribe" exact />
              <Route element={<Payment />} path="payment" exact />
              <Route element={<Success />} path="success" exact />
              <Route element={<Failure />} path="failure" exact />
              <Route element={<Pending />} path="pending" exact />
              <Route element={<NotificationPayment />} path="notification" exact />
              <Route element={<SuccessSend />} path="success-send" exact />
              <Route element={<NotFound />} path="404" exact />
              <Route path="*" element={<Navigate to="404" />} />
            </Route>
          )}
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
