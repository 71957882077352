export const uiStyles = {
  modalVideo: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    '@media (min-width: 718px)': {
      width: 800,
      height: 500
    },
    '@media (max-width: 718px)': {
      width: 600,
      height: 400
    },
    '@media (max-width: 619px)': {
      width: 500,
      height: 300
    },
    '@media (max-width: 508px)': {
      width: 360
    },
    bgcolor: 'background.paper',
    border: 'none',
    borderRadius: 6,
    boxShadow: 24,
    p: 2
  }
};
