// assets
import { IconDashboard, IconUsers, IconFriends, IconBell, IconShare, IconBook, IconFile, IconFileDollar } from '@tabler/icons';

// constant
const icons = { IconDashboard, IconUsers, IconFriends, IconBell, IconShare, IconBook, IconFile, IconFileDollar };

const dashboard = {
  id: 'dashboard',
  title: 'Panel Principal',
  type: 'group',
  children: [
    {
      id: 'default',
      title: 'Panel Principal',
      type: 'item',
      url: '/admin/dashboard',
      icon: icons.IconDashboard,
      breadcrumbs: false
    },
    {
      id: 'admins',
      title: 'Administradores',
      type: 'item',
      url: '/admin/admin-users',
      icon: icons.IconUsers,
      breadcrumbs: false
    },
    {
      id: 'users',
      title: 'Usuarios',
      type: 'item',
      url: '/admin/users',
      icon: icons.IconUsers,
      breadcrumbs: false
    },
    {
      id: 'courses',
      title: 'Cursos',
      type: 'item',
      url: '/admin/courses',
      icon: icons.IconBook,
      breadcrumbs: false
    },
    {
      id: 'notifications',
      title: 'Notificaciones',
      type: 'item',
      url: '/admin/notifications',
      icon: icons.IconBell
    },
    {
      id: 'vouchers',
      title: 'Comprobantes',
      type: 'item',
      url: '/admin/vouchers',
      icon: icons.IconFile
    },
    {
      id: 'payments',
      title: 'Pagos',
      type: 'item',
      url: '/admin/payments',
      icon: icons.IconFileDollar
    }
  ]
};

export default dashboard;
