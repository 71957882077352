import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyD4MgByMVSOca157vJN707960DHOrWvBoc',
  authDomain: 'creah-salud.firebaseapp.com',
  projectId: 'creah-salud',
  storageBucket: 'creah-salud.appspot.com',
  messagingSenderId: '252790107761',
  appId: '1:252790107761:web:cfd9e9821d49e6685635d0'
  /*apiKey: 'AIzaSyC-a0JVcDdU3GZsVPTp22TRM6oNUs9iYUc',
  authDomain: 'creahsaludapp.firebaseapp.com',
  projectId: 'creahsaludapp',
  storageBucket: 'creahsaludapp.appspot.com',
  messagingSenderId: '146586888583',
  appId: '1:146586888583:web:797be2d3c945e53dff8b22'*/
};

const app = initializeApp(firebaseConfig);
export const authentication = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export default app;
