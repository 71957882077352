export function generateId(n) {
  var add = 1,
    max = 10 - add;

  if (n > max) {
    return generateId(max) + generateId(n - max);
  }

  max = Math.pow(10, n + add);
  var min = max / 10;
  var number = Math.floor(Math.random() * (max - min + 1)) + min;

  return ('' + number).substring(add);
}

export function generateOwnReferalNumber(n) {
  var add = 1,
    max = 6 - add;

  if (n > max) {
    return generateOwnReferalNumber(max) + generateOwnReferalNumber(n - max);
  }

  max = Math.pow(10, n + add);
  var min = max / 10;
  var number = Math.floor(Math.random() * (max - min + 1)) + min;

  return ('' + number).substring(add);
}

export const generateIdempotencyKey = () => {
  const length = 20;
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const characterLength = characters.length;
  let idempotencyKey = '';
  for (let i = 0; i < length; i++) {
    idempotencyKey += characters.charAt(Math.floor(Math.random() * characterLength));
  }
  return idempotencyKey;
};
