import React, { useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Chip, Modal, Tooltip } from '@mui/material';
import { IconVideo } from '@tabler/icons';
import { uiStyles } from './index.styles';
import ReactPlayer from 'react-player/lazy';
import { genConst } from 'store/constant';

const VideoSection = () => {
  const theme = useTheme();
  const anchorRef = useRef(null);
  const [openVideo, setOpenVideo] = useState(false);

  const handleToggle = () => {
    setOpenVideo(true);
  };

  const handleCloseVideo = () => {
    setOpenVideo(false);
  };

  const handleError = (error) => {
    console.error('Error playing media:', error);
  };

  return (
    <>
      <Tooltip title="Introducción">
        <Chip
          sx={{
            height: '48px',
            alignItems: 'center',
            borderRadius: '27px',
            transition: 'all .2s ease-in-out',
            marginRight: 2,
            borderColor: theme.palette.primary.light,
            backgroundColor: theme.palette.primary.light,
            '&[aria-controls="menu-list-grow"], &:hover': {
              borderColor: theme.palette.primary.main,
              background: `${theme.palette.primary.main}!important`,
              color: theme.palette.primary.light,
              '& svg': {
                stroke: theme.palette.primary.light
              }
            },
            '& .MuiChip-label': {
              lineHeight: 0
            }
          }}
          label={<IconVideo stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
          variant="outlined"
          ref={anchorRef}
          aria-haspopup="true"
          onClick={handleToggle}
          color="primary"
        />
      </Tooltip>
      <Modal open={openVideo} onClose={handleCloseVideo} aria-labelledby="modal-modal-video" aria-describedby="modal-modal-video-link">
        <Box sx={uiStyles.modalVideo}>
          <ReactPlayer
            className="react-player"
            url={'https://vimeo.com/953636098'}
            width="100%"
            height="90%"
            playing={true}
            loop
            controls
            volume={0.8}
            config={{
              vimeo: {
                playerOptions: {}
              }
            }}
            onError={handleError}
          />
          <p style={{ fontSize: 18, fontWeight: 'bold', textAlign: 'center', color: genConst.CONST_APPBAR }}>Bienvenidos a CREAH SALUD!</p>
        </Box>
      </Modal>
    </>
  );
};

export default VideoSection;
