//Firebase
import { db, authentication } from 'config/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { collection, setDoc, doc, updateDoc, deleteDoc, getDocs, addDoc, where, query, arrayUnion } from 'firebase/firestore';
import {
  collAdminUsers,
  collBanners,
  collBusiness,
  collComments,
  collCourses,
  collGenNoti,
  collInscription,
  collLog,
  collModules,
  collPayments,
  collRating,
  collResponses,
  collSettings,
  collSubscription,
  collUsers,
  collUsrNoti,
  collVoucher
} from 'store/collections';
import { genConst } from 'store/constant';
import { generateId } from 'utils/idGenerator';
import { fullDate } from 'utils/validations';

//Encontrar Sesión activa
export function isSessionActive(navigate) {
  onAuthStateChanged(authentication, (user) => {
    if (user) {
      getProfileUser(user.uid).then((pro) => {
        if (pro == genConst.CONST_PRO_ADM) {
          navigate('/admin/dashboard');
        } else {
          navigate('/app/dashboard');
        }
      });
    }
  });
}

//Buscar si existe Usuario
export async function isExistUser(id) {
  const q = query(collection(db, collUsers), where('id', '==', id));
  const querySnapshot = await getDocs(q);
  return querySnapshot.size;
}

//Encontrar Id de Usuario Sesión
export function getUserId() {
  let userId = null;
  onAuthStateChanged(authentication, (user) => {
    if (user) {
      userId = user.uid;
    }
  });
  return userId;
}

//CRUD FUNCTIONS
export function createDocument(table, idRecord, object) {
  return setDoc(doc(db, table, idRecord), object);
}
export function updateDocument(table, idRecord, object) {
  return updateDoc(doc(db, table, idRecord), object);
}
export function updateDocumentArray(table, idRecord, object) {
  return updateDoc(doc(db, table, idRecord), {
    replies: arrayUnion(object)
  });
}
export function deleteDocument(table, idRecord) {
  return deleteDoc(doc(db, table, idRecord));
}
export function getDocuments(table) {
  return getDocs(collection(db, table));
}
export function createSystemNotification(object) {
  return addDoc(collection(db, collUsrNoti), object);
}
export function createLogRecordWithId(idRecord, object) {
  return setDoc(doc(db, collLog, idRecord), object);
}
//Obtener Datos Perfil de Usuario por ID
export async function getProfileUser(id) {
  let profile = null;
  const q = query(collection(db, collUsers), where('id', '==', id));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    profile = doc.data().profile;
  });
  return profile;
}
//Obtener Datos Perfil de Usuario Administrador por ID
export async function getProfileUserAdmin(id) {
  let profile = null;
  const q = query(collection(db, collAdminUsers), where('id', '==', id));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    profile = doc.data().profile;
  });
  return profile;
}
//Obtener el Estado de una Usuario por ID
export async function getSubscribeStateUser(id) {
  let state = null;
  const q = query(collection(db, collUsers), where('id', '==', id));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    state = doc.data().state;
  });
  return state;
}
//Obtener el estado de la Subscripción de un Usuario por ID
export async function getUserSubscription(id) {
  let state = null;
  const q = query(collection(db, collSubscription), where('idUser', '==', id));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    state = doc.data().state;
  });
  return state;
}
//Obtner el Negocio por Id
export async function getBusinessById(id) {
  let data = [];
  const q = query(collection(db, collBusiness), where('id', '==', id));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    data.push(doc.data());
  });
  return data;
}
//LISTAS
//Obtenemos la lista de Usuarios
export const getUsersData = async () => {
  const list = [];
  const querySnapshot = await getDocs(collection(db, collUsers));
  querySnapshot.forEach((doc) => {
    list.push(doc.data());
    list.sort((a, b) => a.name.localeCompare(b.name));
  });
  return list;
};
//Obtenemos la lista de Banners
export const getBanners = async () => {
  const list = [];
  const querySnapshot = await getDocs(collection(db, collBanners));
  querySnapshot.forEach((doc) => {
    list.push(doc.data());
  });
  return list;
};
// Notifications
export const getGeneralNotifications = async () => {
  const list = [];
  const querySnapshot = await getDocuments(collGenNoti);
  querySnapshot.forEach((doc) => {
    list.push(doc.data());
  });
  return list;
};
// User Notifications
export const getUserNotifications = async () => {
  const list = [];
  const querySnapshot = await getDocuments(collUsrNoti);
  querySnapshot.forEach((doc) => {
    list.push(doc.data());
  });
  return list;
};

//Obtenemos la lista de Usuarios Administradores
export const getAdminUsersData = async () => {
  const list = [];
  const q = query(collection(db, collUsers), where('profile', '==', genConst.CONST_PRO_ADM));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    list.push(doc.data());
  });
  return list;
};
//Obtenemos la lista de Usuarios Administradores
export const getUsersList = async () => {
  const list = [];
  const q = query(collection(db, collUsers), where('profile', '==', genConst.CONST_PRO_STU));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    list.push(doc.data());
  });
  return list;
};
//Obtener lista de Negocios
export async function getBusinessList() {
  const list = [];
  const querySnapshot = await getDocs(collection(db, collBusiness));
  querySnapshot.forEach((doc) => {
    list.push(doc.data());
    list.sort((a, b) => a.name.localeCompare(b.name));
  });
  return list;
}
//Obtenemos la Lista de Cursos
export const getCoursesList = async () => {
  const list = [];
  const querySnapshot = await getDocuments(collCourses);
  querySnapshot.forEach((doc) => {
    list.push(doc.data());
    list.sort((a, b) => a.name.localeCompare(b.name));
  });
  return list;
};
//Obtenemos la Lista de Cursos Gratuitos
export const getFreeCoursesList = async () => {
  let data = [];
  const q = query(collection(db, collCourses), where('isFree', '==', true));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    data.push(doc.data());
  });
  return data;
};
//Obtenemos la Lista de Cursos Pagados
export const getPaidCoursesList = async () => {
  let data = [];
  const q = query(collection(db, collCourses), where('isFree', '==', false));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    data.push(doc.data());
  });
  return data;
};
//Obtenemos los Datos de un Curso por ID
export async function getCourseData(id) {
  let data = [];
  const q = query(collection(db, collCourses), where('id', '==', id));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    data.push(doc.data());
  });
  return data;
}
//Obtenemos los Comentarios de un Curso por ID
export async function getCourseComments(id) {
  let data = [];
  const q = query(collection(db, collComments), where('idCourse', '==', id));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    data.push(doc.data());
    data.sort(function (a, b) {
      var c = new Date(a.createAt);
      var d = new Date(b.createAt);
      return d - c;
    });
  });
  return data;
}
//Obtenemos los Comentarios de un Curso por ID
export async function getCommentsById(id) {
  let data = [];
  const q = query(collection(db, collComments), where('id', '==', id));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    data.push(doc.data());
  });
  return data;
}

//Obtenemos los Comentarios de un Curso por ID
export async function getResponsesByIdComment(id) {
  let data = [];
  const q = query(collection(db, collResponses), where('idComment', '==', id));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    data.push(doc.data());
  });
  return data;
}

//Obtenemos los Comentarios de un Curso por ID y que no esten pendientes
export async function getCourseAprobeComments(id) {
  let data = [];
  const q = query(collection(db, collComments), where('idCourse', '==', id), where('state', '==', 1));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    data.push(doc.data());
    //Sort by Date
    data.sort(function (a, b) {
      var c = new Date(a.createAt);
      var d = new Date(b.createAt);
      return d - c;
    });
  });
  return data;
}
//Obtenemos cantidad de Usuarios Registrados
export const countComments = async (id) => {
  const q = query(collection(db, collComments), where('idCourse', '==', id), where('state', '==', 1));
  const querySnapshot = await getDocs(q);
  const count = querySnapshot.size;
  return count;
};
//Obtenemos los Datos de un Modulo por ID de Curso
export async function getModuleData(id) {
  let data = [];
  const q = query(collection(db, collModules), where('idCourse', '==', id));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    data.push(doc.data());
    data.sort((a, b) => a.order - b.order);
  });
  return data;
}
//Obtenemos los Datos de un Modulo por ID de Curso
export async function getUserInformation(id) {
  let data = [];
  const q = query(collection(db, collUsers), where('id', '==', id));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    data.push(doc.data());
  });
  return data;
}
//Obtenemos el nombre y apellido de Usuario por ID
export async function getUserName(id) {
  let name = null;
  const q = query(collection(db, collUsers), where('id', '==', id));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    name = doc.data().name + ' ' + doc.data().lastName;
  });
  return name;
}
//Total Beneficio
export async function getTotalBenefit() {
  let total = 0;
  const querySnapshot = await getDocuments(collPayments);
  querySnapshot.forEach((doc) => {
    total = total + doc.data().total;
  });
  return total;
}

//STADISTICS COUNT ITEMS
//Obtenemos cantidad de Usuarios Registrados
export const countUser = async () => {
  const q = query(collection(db, collUsers), where('profile', '==', genConst.CONST_PRO_STU));
  const querySnapshot = await getDocs(q);
  const count = querySnapshot.size;
  return count;
};
//Obtenemos cantidad de Usuarios Administradores Registrados
export const countAdminUser = async () => {
  const q = query(collection(db, collUsers), where('profile', '==', genConst.CONST_PRO_ADM));
  const querySnapshot = await getDocs(q);
  const count = querySnapshot.size;
  return count;
};
//Obtenemos cantidad de Negocios Registrados
export const countBusiness = async () => {
  const collCount = collection(db, collBusiness);
  const querySnapshot = await getDocs(collCount);
  const count = querySnapshot.size;
  return count;
};
//Obtenemos cantidad de Negocios Registrados por Usuario ID
export async function countBusinessById(id) {
  const q = query(collection(db, collBusiness), where('userId', '==', id));
  const querySnapshot = await getDocs(q);
  const count = querySnapshot.size;
  return count;
}
//Obtenemos cantidad de Cursos Registrados
export const countCourses = async () => {
  const coursesCollection = collection(db, collCourses);
  const querySnapshot = await getDocs(coursesCollection);
  const courseCount = querySnapshot.size;
  return courseCount;
};
//Obtenemos cantidad de Subscripciones Registradas
export const countSubscriptions = async () => {
  const subsCollection = collection(db, collSubscription);
  const querySnapshot = await getDocs(subsCollection);
  const subsCount = querySnapshot.size;
  return subsCount;
};
//Obtenemos cantidad de Subscripciones Activas Registradas
export async function countActiveSubscriptions() {
  const q = query(collection(db, collSubscription), where('state', '==', 1));
  const querySnapshot = await getDocs(q);
  const count = querySnapshot.size;
  return count;
}
//Saber si un usuario califico el curso
export async function ratingUserByCourseId(courseId, userId) {
  const q = query(collection(db, collRating), where('courseId', '==', courseId), where('userId', '==', userId));
  const querySnapshot = await getDocs(q);
  const count = querySnapshot.size;
  return count;
}
//LOGIN
//Buscamos si correo corresponde a Administrador
export const getAdmin = async (mail) => {
  let isFind = false;
  const q = query(collection(db, collAdminUsers), where('email', '==', mail));
  const querySnapshot = await getDocs(q);
  if (querySnapshot.size > 0) {
    isFind = true;
  } else {
    isFind = false;
  }
  return isFind;
};

export const getUserDataObject = () => {
  return new Promise((resolve, reject) => {
    onAuthStateChanged(
      authentication,
      (user) => {
        if (user) {
          resolve(user);
        } else {
          resolve(null);
        }
      },
      reject
    );
  });
};

export async function getUserCourses(id) {
  let courses = [];
  const q = query(collection(db, collInscription), where('idUser', '==', id), where('isFree', '==', false));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    courses.push(doc.data());
  });
  return courses;
}
export async function getUserCourseState(idCourse, idUser) {
  const q = query(collection(db, collInscription), where('idUser', '==', idUser), where('idCourse', '==', idCourse));
  const querySnapshot = await getDocs(q);
  const count = querySnapshot.size;
  return count;
}
export async function getBankAccounts() {
  let accounts = [];
  const q = query(collection(db, collSettings), where('type', '==', 'CTAACC'));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    accounts.push(doc.data());
  });
  return accounts;
}
//Obtenemos la Lista de Comprobantes
export const getVouchers = async () => {
  const list = [];
  const querySnapshot = await getDocuments(collVoucher);
  querySnapshot.forEach((doc) => {
    list.push(doc.data());
  });
  return list;
};
//Obtenemos la Lista de Pagos
export const getPayments = async () => {
  const list = [];
  const querySnapshot = await getDocuments(collPayments);
  querySnapshot.forEach((doc) => {
    list.push(doc.data());
  });
  return list;
};
//Logs
export const getLogs = async () => {
  const list = [];
  const querySnapshot = await getDocuments(collLog);
  querySnapshot.forEach((doc) => {
    list.push(doc.data());
  });
  return list;
};
//Log Record
export function createLogRecord(table, process, object) {
  const idLog = generateId(10);
  const logObject = {
    id: idLog,
    process: process,
    createAt: fullDate(),
    state: genConst.CONST_STATE_AC,
    object: object,
    collection: table
  };
  return setDoc(doc(db, collLog, idLog), logObject);
}

export async function getCourseInfo(id) {
  let data = [];
  const q = query(collection(db, collCourses), where('id', '==', id));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    data.push(doc.data());
  });
  return data;
}
