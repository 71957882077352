// theme constant
export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;
export const ctaAccount = 'CTAACC';
export const bankData = {
  BANK_TYPE_1: 'Ahorros',
  BANK_TYPE_2: 'Corriente',
  BANK_NAME_1: 'Banco Pichincha',
  BANK_CTA_1: '600000001',
  BANK_CTA_USR_1: 'Sebastian Ándres Orellana Vallejo',
  BANK_CTA_ID_1: '1712000000',
  BANK_NAME_2: 'Banco Del Pacífico',
  BANK_CTA_2: '600000002',
  BANK_CTA_USR_2: 'Sebastian Ándres Orellana Vallejo',
  BANK_CTA_ID_2: '1712000000',
  BANK_NAME_3: 'Banco Produbanco',
  BANK_CTA_3: '600000003',
  BANK_CTA_USR_3: 'Sebastian Ándres Orellana Vallejo',
  BANK_CTA_ID_3: '1712000000'
};
export const mercadoPago = {
  TITLE: 'Mercado Pago',
  SUBTITLE: 'Muy Pronto!!!',
  DESC: '09876266272'
};

export const process = {
  LOG_USER_REGISTER: 'REGISTRO DE USUARIO',
  LOG_USER_LOGIN: 'INICIO DE SESIÓN DE USUARIO',
  LOG_CREATE_ADMIN: 'CREACIÓN DE ADMINISTRADOR',
  LOG_EDIT_ADMIN: 'ACTUALIZACIÓN DE ADMINISTRADOR',
  LOG_DELETE_ADMIN: 'ELIMINACIÓN DE ADMINISTRADOR',
  LOG_CREATE_USER: 'CREACIÓN DE USUARIO',
  LOG_EDIT_USER: 'ACTUALIZACIÓN DE USUARIO',
  LOG_DELETE_USER: 'ELIMINACIÓN DE USUARIO',
  LOG_CREATE_PARAM: 'CREACIÓN DE PARÁMETRO',
  LOG_EDIT_PARAM: 'ACTUALIZACIÓN DE PARÁMETRO',
  LOG_DELETE_PARAM: 'ELIMINACIÓN DE PARÁMETRO',
  LOG_CREATE_TRAN: 'CREACIÓN DE TRANSACCIÓN',
  LOG_EDIT_TRAN: 'ACTUALIZACIÓN DE TRANSACCIÓN',
  LOG_DELETE_TRAN: 'ELIMINACIÓN DE TRANSACCIÓN',
  LOG_CREATE_COURSE: 'CREACIÓN DE PROGRAMÁ',
  LOG_EDIT_COURSE: 'ACTUALIZACIÓN DE PROGRAMÁ',
  LOG_DELETE_COURSE: 'ELIMINACIÓN DE PROGRAMÁ',
  LOG_CREATE_MODULE: 'CREACIÓN DE MÓDULO',
  LOG_EDIT_MODULE: 'ACTUALIZACIÓN DE MÓDULO',
  LOG_DELETE_MODULE: 'ELIMINACIÓN DE MÓDULO',
  LOG_CREATE_BUSINESS: 'CREACIÓN DE NEGOCIO',
  LOG_EDIT_BUSINESS: 'ACTUALIZACIÓN DE NEGOCIO',
  LOG_DELETE_BUSINESS: 'ELIMINACIÓN DE NEGOCIO',
  LOG_CREATE_PROD: 'CREACIÓN DE PRODUCTO',
  LOG_EDIT_PROD: 'ACTUALIZACIÓN DE PRODUCTO',
  LOG_DELETE_PROD: 'ELIMINACIÓN DE PRODUCTO',
  LOG_MERCADO_PAGO: 'MERCADO PAGO API',
  LOG_SEND_VOUCHER: 'ENVIO DE COMPROBANTE',
  LOG_DELETE_VOUCHER: 'ALIMINACIÓN DE COMPROBANTE',
  LOG_CREATE_REGCOURSE: 'INSCRIPCIÓN DE USUARIO AL CURSO',
  LOG_EDIT_REGCOURSE: 'ACTUALIZACIÓN DE INSCRIPCIÓN',
  LOG_DELETE_REGCOURSE: 'ELIMINACIÓN DE USUARIO AL CURSO',
  LOG_CREATE_PAYMENT: 'NUEVO PAGO EFECTUADO',
  LOG_EDIT_PAYMENT: 'PAGO ACTUALIZADO',
  LOG_DELETE_PAYMENT: 'PAGO ELIMINADO'
};

export const genConst = {
  CONST_PRO_ADM: 1001,
  CONST_PRO_STU: 2002,
  CONST_PRO_VIS: 3003,
  CONST_ADM_NOT: 'Administrador del Sistema',
  CONST_PRO_ADM_TXT: 'Administrador',
  CONST_PRO_STU_TXT: 'Usuario',
  CONST_PRO_VIS_TXT: 'Visitante',
  CONST_COU_IS_FREE: 'Gratuito',
  CONST_COU_IS_NO_FREE: 'Pagado',
  CONST_STA_ACT: 1,
  CONST_STA_INACT: 2,
  CONST_STATE_IN: 0,
  CONST_STATE_AC: 1,
  CONST_STATE_PN: 2,
  CONST_NOTIF_NL: 0,
  CONST_NOTIF_LE: 1,
  CONST_STATE_APR: 1,
  CONST_STATE_NO_APR: 2,
  CONST_COMP_STATE_PEND: 1,
  CONST_COMP_STATE_APRO: 2,
  CONST_STA_ACT_TXT: 'Activo',
  CONST_STA_INACT_TXT: 'Inactivo',
  CONST_STA_APR_TXT: 'Aprobado',
  CONST_STA_NO_APRO_TXT: 'Pendiente',
  CONST_EXT_IMAGE: '.jpg',
  CONST_EXT_PDF: '.pdf',
  CONST_EXT_VIDEO_MP4: '.mp4',
  CONST_EXT_VIDEO_AVI: '.avi',
  CONST_PRIMARY_COLOR: '#53338a',
  CONST_SECONDARY_COLOR: '#a8afda',
  CONST_THIRD_COLOR: '#04a604',
  CONST_CREATE_COLOR: '#53338a',
  CONST_VIEW_COLOR: '#a8afda',
  CONST_UPDATE_COLOR: '#53338a',
  CONST_DELETE_COLOR: '#ff6961',
  CONST_CANCEL_COLOR: '#a8afda',
  CONST_SUCCESS_COLOR: '#04a604',
  CONST_INFO_COLOR: '#828cc9',
  CONST_ERROR_COLOR: '#ff6961',
  CONST_WARNING_COLOR: '#fdfd96',
  CONST_APPBAR_SEARCH: '#f5f6f8',
  CONST_APPBAR: '#53338a',
  CONST_MTD_1_LBL: 'Tarjeta Débito / Crédito',
  CONST_MTD_2_LBL: 'Depósito / Transferencia',
  CONST_MTD_3_LBL: 'De Una',
  CONST_MTD_4_LBL: 'PayPal',
  CONST_MTD_5_LBL: 'Mercado Pago',
  CONST_MTD_6_LBL: 'Otro',
  CONST_MTD_7_LBL: 'Otro',
  CONST_MTD_1_VAL: 1,
  CONST_MTD_2_VAL: 2,
  CONST_MTD_3_VAL: 3,
  CONST_MTD_4_VAL: 4,
  CONST_MTD_5_VAL: 5,
  CONST_MTD_6_VAL: 6,
  CONST_CTA_AHO_DES: 'CTA. AHORROS',
  CONST_CTA_AHO_VAL: 'CTAAHO',
  CONST_CTA_COR_DES: 'CTA. CORRIENTE',
  CONST_CTA_COR_VAL: 'CTACORR',
  CONST_MONTH_VALUE: 30,
  CONST_YEAR_VALUE: 300,
  CONST_MONTH_DAYS: 30,
  CONST_YEAR_DAYS: 365,
  CONST_MONTH_CHAR: 1,
  CONST_YEAR_CHAR: 2,
  CONST_IVA: 0.12
};
