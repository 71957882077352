// assets
import { IconKey, IconBell, IconMail, IconSettings, IconSettings2, IconPhotoUp, IconFile } from '@tabler/icons';

// constant
const icons = {
  IconKey,
  IconBell,
  IconMail,
  IconSettings,
  IconSettings2,
  IconPhotoUp,
  IconFile
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
  id: 'pages',
  title: 'Configuraciones',
  type: 'group',
  children: [
    {
      id: 'banner',
      title: 'Banners',
      type: 'item',
      url: '/admin/banner',
      icon: icons.IconPhotoUp
    },
    {
      id: 'settings',
      title: 'Parámetros',
      type: 'item',
      url: '/admin/settings',
      icon: icons.IconSettings
    }
  ]
};

export default pages;
